import React from 'react';
import { Route, Redirect, matchPath } from 'react-router-dom';
import { AppLanguage } from '@translations/AppLanguage';
import history from '@utils/history';
import { Routes } from '@navigator/Routes';
import { i18n } from '@translations/i18n';

interface IProps {
  RouterComponent: React.ComponentClass<any>;
  defaultLanguage?: string;
  children: any;
}

export const LocalizedRouter: React.FC<IProps> = ({ RouterComponent, defaultLanguage, children }) => {
  return (
    <RouterComponent history={history}>
      <Route path={Routes.Home.lang}>
        {({ location }) => {
          /**
           * Get current language
           * Set default locale to en if base path is used without a language
           */
          const lang = matchPath<{ lang: string }>(location.pathname, { path: Routes.Home.lang })?.params.lang || defaultLanguage || AppLanguage.English;

          /**
           * If language is not in route path, redirect to language root
           */
          if (!location.pathname.startsWith(`/${lang}`)) {
            return <Redirect to={`/${lang}${Routes.Home.home}${location.search}`} />;
          } else if (lang !== i18n.language) {
            const [, route] = location.pathname.split(`/${lang}`);
            if (route?.includes("discourse")) {
              return <Redirect to={`/${"en"}${route}${location.search}`} />;
            } else {
              return <Redirect to={`/${i18n.language}${route}${location.search}`} />;
            }
          }

          /**
           * Return Intl provider with default language set
           */
          return children;
        }}
      </Route>
    </RouterComponent>
  );
};
